import { Link } from 'gatsby'
import React from 'react'
import MobileMenu from '../mobileMenu'
import Logo from './../../../images/header-logo.svg'

export default function Header() {
    const goToElement = (id) => {
        const element = document.getElementById(id)
        element.scrollIntoView({ block: 'start', behavior: 'smooth' })
    }
    return (
        <header id="header" className="p-4 fixed z-10 w-full transition-500 transition-all" style={{height:"80px"}}>
            <nav className=" container flex gap-5 justify-between items-center">
                <div className="logo shrink-0">
                    <img src={Logo} alt="logo" />
                </div>
                <ul className=" gap-7 items-center hidden lg:flex lg:ml-auto">
                    <li>
                        <a role={'button'} className="font-semibold" onClick={() => goToElement('home')}>
                            Inicio
                        </a>
                    </li>
                    <li>
                        <a
                            role={'button'} className="font-semibold"
                            onClick={() => goToElement('profile')}
                        >
                            Doctor Baldwin
                        </a>
                    </li>
                    <li>
                        <a
                            role={'button'} className="font-semibold"
                            onClick={() => goToElement('services')}
                        >
                            Servicios
                        </a>
                    </li>
                    <li>
                        <a
                            role={'button'} className="font-semibold"
                            onClick={() => goToElement('testimonies')}
                        >
                            Testimonios
                        </a>
                    </li>
                    <li>
                        <a
                            role={'button'} className="font-semibold"
                            onClick={() => goToElement('contact')}
                        >
                            Contacto
                        </a>
                    </li>
                    <li className=" font-semibold bg-blue-brand rounded-primary w-max px-4 py-2 pointer text-white font-monrope hover:shadow-lg">
                        <a
                            target={'_blank'}
                            href="https://wa.me/51 989868701?text=%C2%A1Hola!%20Acabo%20de%20visitar%20tu%20p%C3%A1gina%20y%20tengo%20una%20consulta.%20%F0%9F%91%8B"
                        >
                            Agenda una cita
                        </a>
                    </li>
                </ul>
                <MobileMenu />
            </nav>
        </header>
    )
}
