import React from 'react'
// components
import Header from './header'
import Footer from './footer'
import FloatingWhatsApp from 'react-floating-whatsapp'
import 'react-floating-whatsapp/dist/index.js'


export default function Layout({ children }) {
    return (
        <main className="">
            <Header />
            {children}
            <Footer />
            <FloatingWhatsApp phone='51989868701' popupMessage="Bienvenido a Dental Baldwin, ¿como podemos ayudarte?" />
        </main>
    )
}
