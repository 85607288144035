import React from 'react'
import Menu from './../../../images/menu_movil.svg'

export default function MobileMenu() {
    const [isOpen, setIsOpen] = React.useState(false)
    const goToElement = (id) => {
        const element = document.getElementById(id)
        element.scrollIntoView({ block: 'start', behavior: 'smooth' })
        setIsOpen(false)
    }
    const clickHandler = () => {
        setIsOpen(!isOpen)
    }
    return (
        <div className="block lg:hidden relative">
            <img src={Menu} alt="menu" className="cursor-pointer" onClick={clickHandler} />
            {isOpen && <div className="p-4 rounded-md shadow-xl absolute flex flex-col w-40 right-0 bg-white">
                <a role={'button'} onClick={() => goToElement('home')}>
                    Inicio
                </a>
                <a role={'button'} onClick={() => goToElement('profile')}>
                    Doctor Baldwin
                </a>
                <a role={'button'} onClick={() => goToElement('services')}>
                    Servicios
                </a>
                <a role={'button'} onClick={() => goToElement('testimonies')}>
                    Testimonios
                </a>
                <a role={'button'} onClick={() => goToElement('contact')}>
                    Contacto
                </a>
            </div>}
        </div>
    )
}
