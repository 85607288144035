import React from 'react'
//images
import FooterLogo from './../../../images/header-logo.svg'
import Facebook from './../../../images/icono_facebook.svg'
import Twitter from './../../../images/twitter-icon.png'
import Youtube from './../../../images/icono_youtube.svg'

import Wsp from './../../../images/wsp-fill-icon.svg'
import Phone from './../../../images/phone-icon.svg'
import Mail from './../../../images/mail-icon.svg'
import Heart from './../../../images/heart-icon.png'

export default function Footer() {
    const goToElement = (id) => {
        const element = document.getElementById(id)
        element.scrollIntoView({ block: 'start', behavior: 'smooth' })
    }
    return (
        <>
            <footer className="bg-ligther-blue-brand ">
                <div className="container py-20">
                    <div
                        className="grid lg:grid-cols-2 gap-10"
                        style={{ alignItems: 'center' }}
                    >
                        <div>
                            <img src={FooterLogo} alt="Dental Baldwin" />
                            <p
                                className="text-black-brand text-xs mt-4"
                                style={{ maxWidth: '380px' }}
                            >
                                En <span className='font-semibold'>Dental Baldwin</span> te ofrecemos servicios
                                odontológicos restauradores, estéticos, así como
                                tratamientos quirúrgicos e implantes dentales.
                            </p>
                            <div className="flex gap-5 mt-16">
                                <a
                                    href="https://www.facebook.com/dentalbaldwin"
                                    target={'_blank'}
                                >
                                    <img src={Facebook} alt="facebook" />
                                </a>

                                <a
                                    href="https://youtube.com/channel/UCm8zB701xdQUPzS6D1umHow"
                                    target={'_blank'}
                                >
                                    <img src={Youtube} alt="Youtube" />
                                </a>
                            </div>
                        </div>
                        <div id="contact" className="w-full grid grid-cols-2">
                            <div>
                                <div className="mt-16 flex flex-col gap-5">
                                    <a
                                        role={'button'}
                                        onClick={() => goToElement('home')}
                                        className="text-black-brand text-xs font-manrope"
                                    >
                                        Inicio
                                    </a>
                                    <a
                                        role={'button'}
                                        onClick={() => goToElement('profile')}
                                        className="text-black-brand text-xs font-manrope"
                                    >
                                        Doctor Baldwin
                                    </a>
                                    <a
                                        role={'button'}
                                        onClick={() => goToElement('services')}
                                        className="text-black-brand text-xs font-manrope"
                                    >
                                        Servicios
                                    </a>
                                    <a
                                        role={'button'}
                                        onClick={() =>
                                            goToElement('testimonies')
                                        }
                                        className="text-black-brand text-xs font-manrope"
                                    >
                                        Testimonios
                                    </a>
                                    {/* <a role={"button"} className="text-black-brand">
                                    Contacto
                                    </a> */}
                                </div>
                            </div>
                            <div>
                                <h5 className="text-black-brand text-sm font-bold">
                                    Contacto
                                </h5>
                                <div className="mt-10 flex flex-col gap-5 item-end">
                                    <a
                                        href="https://wa.me/51989868701?text=%C2%A1Hola!%20Acabo%20de%20visitar%20tu%20p%C3%A1gina%20y%20tengo%20una%20consulta.%20%F0%9F%91%8B"
                                        target={'_blank'}
                                        className="flex gap-3 text-black-brand text-xs"
                                    >
                                        <img src={Wsp} alt="Wsp" />
                                        <img
                                            src={Phone}
                                            alt="numero de telefono"
                                        />
                                        +51 989868701
                                    </a>
                                    <a
                                        href="tel:+51989868701"
                                        target={'_blank'}
                                        className="flex gap-3 text-black-brand text-xs"
                                    >
                                        <img
                                            src={Phone}
                                            alt="numero de telefono"
                                        />
                                        +51 989868701
                                    </a>
                                    <a
                                        href="mailto:winston@dentalbadwin.com"
                                        target={'_blank'}
                                        mai
                                        className="flex gap-3 text-black-brand text-xs"
                                    >
                                        <img
                                            src={Mail}
                                            alt="numero de telefono"
                                        />
                                        winston@dentalbadwin.com
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <div>
                <div
                    className="flex justify-center p-2 gap-1 text-white text-sm"
                    style={{ backgroundColor: '#343947' }}
                >
                    <span>crafted with</span>
                    <img src={Heart} alt="heart" />
                    <a href="https://programate.pe/" target={'_blank'}>
                        by Prográmate.pe
                    </a>
                </div>
            </div>
        </>
    )
}
